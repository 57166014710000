import {
  Center,
  Image,
  Container,
  Text,
  VStack
} from '@chakra-ui/react'

export const NotFound = () => {
  return (
    <Container marginTop={5} marginBottom={25} maxW={"85ch"} bg={"white"} padding={5} borderRadius={"lg"}>
      <Container>
        <VStack
          marginBottom={8}
          align='stretch'
        >
          <Image src="lomic-law-logo-new.png" alt='Lomic Law Logo' />
          <Center><Text fontSize='2xl'>Canadian Trademark Application</Text></Center>
          <Center marginTop='20px'><Text fontSize='xl'>Page Not Found.</Text></Center>
        </VStack>
      </Container>
    </Container>
  )
}
