import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  Box,
} from '@chakra-ui/react'

export const ContactPersonFormSection = ({register, errors}: {register: any, errors: any}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <FormControl isInvalid={errors.contactPersonName !== undefined} isRequired>
          <FormLabel htmlFor='contactPersonName'>Full Name</FormLabel>
          <Input
            id='contactPersonName'
            placeholder='Brian Jones'
            {...register('contactPersonName', {
              required: true,
              
            })}
          />
          <FormErrorMessage>
            {errors.contactPersonName?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <FormControl isInvalid={errors.contactPersonEmail !== undefined} isRequired>
          <FormLabel htmlFor='contactPersonEmail'>Email</FormLabel>
          <Input
            id='contactPersonEmail'
            type='email'
            placeholder='brian.jones@example-llp.com'
            {...register('contactPersonEmail', {
              required: true,
              validate: {
                emailDomain: (email: string) => {
                  if (email.toLocaleLowerCase().endsWith("@lomic-law.ca")) {
                    return undefined;
                  } else {
                    return "Recipient must use Lomic Law email"
                  }
                  
                }
              }
            })}
          />
          <FormErrorMessage>
            {errors.contactPersonEmail?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </VStack>
  )
}
