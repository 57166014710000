import {
  Center,
  Image,
  Container,
  Text,
  VStack,
  HStack,
  Box,
  UnorderedList,
  ListItem,
  Spinner,
  Link,
  Button
} from '@chakra-ui/react'
import React, {useState, useEffect} from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'

export const DraftTrademarkApplicationSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box>
        <HStack alignContent={"center"}>
          <Box
            borderRadius="full" // Makes the box circular
            bg="green.500" // Solid red background
            color="white" // White icon color
            display="flex" // Use Flexbox for centering
            alignItems="center" // Center vertically
            justifyContent="center" // Center horizontally
            width={8} // Width of the circle
            height={8} // Height of the circle
          >
            <CheckIcon />
          </Box>
          <Text fontSize='3xl'>Success</Text>
        </HStack>
        <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
          <Text>In a few minutes, you will receive an e-mail with the XML application. The XML application will also be uploaded to Dropbox.</Text>
        </VStack>
        <Center mt="20px">
          <Box>
            <Button colorScheme='teal' flex={1} backgroundColor="#0C3C60" sx={{ _hover: {backgroundColor: "#39729B"} }} onClick={() => navigate(0)}>
              Submit New Trademark Application
            </Button>
          </Box>
        </Center>
      </Box>
  )
}
