import { useForm } from 'react-hook-form'
import {
  Button,
  Center,
  Image,
  Container,
  Text,
  VStack,
  Box,
  Flex,
  useToast,
  Spinner,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage
} from '@chakra-ui/react'
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, Dispatch, SetStateAction } from 'react';

export enum AuthenticationStatus {
  CHECKING,
  NOT_AUTHENTICATED,
  AUTHENTICATED
}

export type AuthenticatedUser = {
	authenticated: AuthenticationStatus,
	name: string | null | undefined,
	email: string | null | undefined
};

export const LoginForm = ({setAuthenticationStatus, setMainFormValue} : {setAuthenticationStatus: Dispatch<SetStateAction<AuthenticationStatus>>, setMainFormValue: any}) => {
  const loginSuccessToast = useToast()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({})

  function onSubmit(values: any) {
		loginWithErrorHandling(values)
  }

  function createFormData(values: any) : FormData {
    const formData = new FormData();
    formData.append('username', values.username);
		formData.append('password', values.password);
    return formData
  }

  async function login(formData: FormData): Promise<void> {
		try {
			const response = await fetch(`${process.env.REACT_APP_TOBY_BACKEND_API_URL}/login`, {
				method: "POST",
				body: formData,
				credentials: 'include'
			});
	
			const data = await response.json();
			if (response.ok && data.authenticated) {
				setMainFormValue('contactPersonName', data.name);
				setMainFormValue('contactPersonEmail', data.email);
				return Promise.resolve();
			}
		} catch(_) {}
		return Promise.reject();
  }

  async function loginWithErrorHandling(values: any) {
    const formData = createFormData(values);
		const loginPromise = login(formData);

    loginSuccessToast.promise(loginPromise, {
      success: { title: 'Login Successful' },
      error: { title: 'Login Attempt Failed', description: 'Please double-check your credentials and try again.' },
      loading: { title: 'Verifying Login Credentials', description: 'Please wait' },
    });

		loginPromise.then(() => {
			setAuthenticationStatus(AuthenticationStatus.AUTHENTICATED)
		}).catch(_ => {
			setAuthenticationStatus(AuthenticationStatus.NOT_AUTHENTICATED)
		})
  }

  return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
				<Box>
					<FormControl isInvalid={errors.username !== undefined} isRequired>
						<FormLabel htmlFor='username'>Username</FormLabel>
						<Input
							id='username'
							placeholder=''
							{...register('username', {
								required: true,
							})}
						/>
						<FormErrorMessage>
							{errors.username?.message?.toString()}
						</FormErrorMessage>
					</FormControl>
				</Box>
				<Box>
					<FormControl isInvalid={errors.password !== undefined} isRequired>
						<FormLabel htmlFor='password'>Password</FormLabel>
						<Input
							id='password'
							type='password'
							{...register('password', {
								required: true,
							})}
						/>
						<FormErrorMessage>
							{errors.password?.message?.toString()}
						</FormErrorMessage>
					</FormControl>
				</Box>
				<Box mt={2}>
					<Button type='submit'>Login</Button>
				</Box>
			</VStack>
		</form>
	)
}
